<template>
  <div class="container">
    <!-- <div class="img">
      <img class="bg" src="../../assets/index.jpg" alt="" />
    </div> -->
    <div class="noticeContainer">
      <div class="flex_jcsa">
        <div class="left">
          <div class="searchBack">
            <Search :cityId="city_id" :searchIndex="'searchIndex'" @searchIndex="searchIndex" />
          </div>
          <div class="memberbox">
            <RegionalMembers :select="select" :cityId="city_id" :isClass="true" :classifyList="cityMembersList" @changeSecondClassify="changeSecondClassify" />
          </div>
        </div>
        <div class="login">
          <img :src="userInfo ? userInfo.avatarUrl : require('../../assets/images/touxiang.png')" />
          <div class="title">{{userInfo ? userInfo.nickName : 'Hi，欢迎登录'}}</div>
          <div  class="flex_jc_ac bottom_pay" v-if="userInfo">
            <el-button class="widthBut" type="primary" v-if="userInfo.is_info === 0" @click="settleIn">我要入驻</el-button>
            <el-button class="widthBut" type="primary" v-else-if="userInfo.is_certification === 0" @click="settleIn">去实名认证</el-button>
            <el-button class="widthBut" type="primary" v-else @click="settleIn">我的信息</el-button>
          </div>
          <div class="flex_jc_ac bottom_pay" v-else>
             <el-button class="widthButlogin" type="primary" @click="loginClick('0')">登录</el-button>
             <el-button class="widthButton" type="primary" plain @click="loginClick('1')">注册</el-button>
          </div>
        </div>
      </div>
      <!-- 集采服务 -->
      <Swiper />
      <!-- 热门项目 -->
      <div class="flex_jcsb_ac collectborder popularBox">
        <div class="collect">热门项目</div>
        <div class="more" @click="projectSearch">查看更多</div>
      </div>
      <ProjectList :list="projectesList" />
      <div class="cardTop">
        <CardList />
      </div>
    </div>
  </div>
</template>

<script>
import RegionalMembers from '@/components/sort/regionalMembers'
import CardList from '@/components/common/cardList'
import Search from '@/components/common/search'
import Swiper from '@/components/common/swiper'
import ProjectList from '@/components/common/projectList'
import { cityList, ProjectDataList } from '@/api/index/index'
export default {
  name: 'index',
  components: {
    Search,
    Swiper,
    RegionalMembers,
    ProjectList,
    CardList
  },
  data() {
    return {
      userInfo: {}, // 个人信息
      projectesList: [], // 项目数组
      cityMembersList: [], // 地区会员
      city_id: 0, // 城市id
      select: '项目'
    }
  },
  mounted() {
    this.regionalMmbers()
    this.project()
    this.userInfo = JSON.parse(localStorage.getItem('userInfo'))
    if (localStorage.getItem('cityLetterId')) {
      this.city_id = parseInt(localStorage.getItem('cityLetterId'))
      localStorage.removeItem('cityLetterId')
    }
  },
  created() {
    localStorage.removeItem('loginType')
  },
  methods: {
    projectSearch() {
      this.$router.push('projectSearch')
    },
    searchIndex(val) {
      this.select = val === 1 ? '项目' : '用户'
    },
    changeSecondClassify(id) { // 地区会员 返回id
      this.city_id = id
      if (this.select === '项目') {
        localStorage.setItem('approverStatus', JSON.stringify('/projectSearch'))
        this.$router.push({
          path: '/projectSearch',
          name: 'projectSearch',
          params: {
            city_id: this.city_id,
            keyword: this.keyword
          }
        })
      } else {
        localStorage.setItem('approverStatus', JSON.stringify('/memberShow'))
        this.$router.push({
          path: '/memberShow',
          name: 'memberShow',
          params: {
            city_id: this.city_id,
            keyword: this.keyword
          }
        })
      }
    },
    settleIn() { // 我要入驻
      this.$router.push('/user')
    },
    loginClick(type) { // 登录 注册
      // this.$store.state.modeuls.user.logRegister = type;
      localStorage.setItem('loginType', type);
      this.$router.push('/login')
    },
    regionalMmbers() {
      const data = {
        pid: 0
      }
      cityList(data).then(res => {
        this.cityMembersList = res.data
      })
    },
    project() {
      const data = {
        page: 1,
        psize: 8,
        sort: 2
      }
      ProjectDataList(data).then(res => {
        this.projectesList = res.data.list
      })
    }
  }
}
</script>

<style lang="scss" scoped>
  .container{
    .img{
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      .bg{
        position: absolute;
        top: 0;
        width: 100%;
        height: 600px;
        z-index: -1;
        object-fit: cover;
      }
    }
    .noticeContainer{
      width: 1200px;
      margin: 40px auto;
      position: relative;
      .cardTop{
        margin-top: 30px;
      }
      .popularBox{
        margin: 40px 0 19px 0;
      }
      .collectborder{
        border-bottom: 1px solid #EEF3F8;
        padding-bottom: 15px;
        .more{
          color: #666;
          font-size: 14px;
          &:hover{
            cursor: pointer;
            color: #1166CF;
          }
        }
      }
      .collect{
        color: #1166CF;
        font-size: 18px;
        font-weight: bold;
      }
      .more{
        color: #999;
        font-size: 14px;
        cursor: pointer;
      }
      .left{
        flex: 1;
        .searchBack{
          padding: 0 20px;
          line-height: 100px;
          background-color: #fff;
          margin-bottom: 12px;
        }
        .memberbox{
          background: #fff;
          padding: 0 20px;
          height: 113px;
          overflow: hidden;
        }
      }
      .login{
        width: 280px;
        background: #fff;
        // height: 218px;
        margin-left: 12px;
        // padding: 24px 32px;
        text-align: center;
        .widthBut{
          width: 200px;
        }
        .widthButlogin{
          width: 98px;
        }
        .widthButton{
          width: 98px;
          margin-left: 30px;
        }
        img{
          width: 66px;
          height: 66px;
          border-radius: 50%;
          object-fit: cover;
          margin-top: 24px;
        }
        .title{
          font-size: 16px;
          margin-top: 16px;
        }
        .bottom_pay{
          margin-top: 30px;
        }
      }
    }
  }
</style>
