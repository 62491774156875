<template>
    <div class="cardList flex">
        <div :class="['cardBox', column === 3 ? 'even' : 'odd', marginRight === 15 ? 'marginRight15' : 'marginRight28']"
        v-for="(item,index) in cateList" :key="index" @click="projectSearch(item.id)">
          <div class="bg">
            <img :src="item.pic" />
          </div>
          <span>{{item.name}}</span>
        </div>
      </div>
</template>

<script>
import { cateList } from '@/api/index/index'
export default {
  name: 'ProjectList',
  props: {
    column: {
      type: Number,
      default: 3
    },
    marginRight: {
      type: Number,
      default: 15
    }
  },
  data() {
    return {
      cateList: [] // 分类一级列表
    }
  },
  mounted() {
    this.cateHome()
  },
  methods: {
    projectSearch(id) {
      this.$router.push({name: 'projectSearch', query: {id}})
    },
    cateHome() {
      const data = {
        pid: 0,
        level: 0
      }
      cateList(data).then(res => {
        this.cateList = res.data
      })
    }
  }
}
</script>

<style lang="scss" scoped>
  .cardList {
    flex-wrap: wrap;
    .cardBox{
      margin-bottom: 16px;
      cursor: pointer;
      box-sizing: border-box;
      position: relative;
      width: 390px;
      height: 140px;
      .bg{
        position: absolute;
        top: 0;
        width: 100%;
        height: 100%;
        // overflow: hidden;
        img{
          width: 100%;
          height: 100%;
          transition:all 2s;/*图片放大过程的时间*/
        }
      }
      span{
        line-height: 140px;
        text-align: center;
        position: absolute;
        font-size: 26px;
        color: #fff;
        width: 100%;
        text-align: center;
      }
      &:hover{
        .bg{
          img{
            width: 100%;
            height: 100%;
            transform: scale(1.05);  /*以y轴为中心旋转*/
          }
        }
      }
      &.even:nth-child(3n+3){
        margin-right: 0;
      }
      &.marginRight15{
        margin-right: 15px;
      }
    }
  }
</style>
