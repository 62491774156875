<template>
  <div class="swiperContainer">
    <div class="flex top">
        <div class="swiperWidth">
          <el-carousel>
            <el-carousel-item v-for="item in swiperList" :key="item.id">
              <a :href="item.url" target="_blank">
                <img :src="item.pic"/>
              </a>
            </el-carousel-item>
          </el-carousel>
        </div>
        <div class="collectBox">
          <div class="flex_jcsb_ac collectborder">
            <div class="collect">集采服务</div>
            <div class="more" @click="collectMoentClick">查看更多</div>
          </div>
          <!-- <div class="ellipsis-1"> -->
            <div v-for="(item,index) in articleList" :key="index" class=" collectul flex_ac" @click="collectMoent(item.id)">
              <div class="text"></div>
              <div class=" flexOne flex_ac">
                <div style="width:350px" class="ellipsis-1">{{ '【' + item.name + '】'}} {{item.intro}}</div>
              </div>
            </div>
          <!-- </div> -->
          <div class="enterpriseBox">
            <div class="flex_jcsb_ac">
              <div class="enterprise flex_ac">
                <img src="../../assets/images/icon/qiye.png" />
                <span>入驻企业</span>
              </div>
              <div class="num">{{homeNumInfo.company_num}}</div>
            </div>
            <div class="flex_jcsb_ac">
              <div class="enterprise flex_ac">
                <img src="../../assets/images/icon/xuqiu.png" />
                <span>项目总数</span>
              </div>
              <div class="num">{{homeNumInfo.project_num}}</div>
            </div>
          </div>
        </div>
      </div>
  </div>
</template>

<script>
import { BannerList, bannerHome, ArticleList } from '@/api/index/index'
export default {
  props: {
    isGuidePage: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      swiperList: [],
      homeNumInfo: {}, // 主页信息
      articleList: [] // 集菜列表
    }
  },
  mounted() {
    this.banner()
    this.bannerHome()
    this.article()
  },
  methods: {
    // routerLink(url) {
    //   this.$emit('routerLink', url)
    //   // window.location.href = this.url
    // },
    collectMoent(id) {
      this.$router.push({path: 'collectionDetails', query: { id }})
    },
    collectMoentClick() {
      this.$router.push({path: 'collect'})
    },
    bannerHome() {
      bannerHome().then(res => {
        this.homeNumInfo = res.data
      })
    },
    article() {
      const data = {
        page: 1,
        psize: 8
      }
      ArticleList(data).then(res => {
        this.articleList = res.data.list
      })
    },
    banner() {
      const data = {
        place: 0
      }
      BannerList(data).then(res => {
        this.swiperList = res.data
      })
    }
  }
}
</script>
<style lang="scss" scoped>
  /deep/{
    .el-carousel__container{
      height: 420px;
      width: 100%;
      white-space: none;overflow: none;
    }
    .el-carousel{
      cursor: pointer;
      .el-carousel__button{
        width: 32px;
        height: 4px;
        opacity: 1;
      }
      .el-carousel__indicator.is-active button{
        background: #FFD800;
      }
      img{
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }
  .top{
    margin-top: 20px;
    .swiperWidth{
      flex: 1;
    }
    ::-webkit-scrollbar {
      display: none; /* Chrome Safari */
    }
    .collectBox{
      width: 360px;
      margin-left: 12px;
      background: #FFFFFF;
      padding: 20px;
      position: relative;
      .collect{
        font-size: 18px;
        font-family: Source Han Sans CN;
        font-weight: bold;
        color: #1166CF;
      }
      .enterpriseBox{
        // margin-top: 13px;
        width: 92%;
        position: absolute;
        bottom: 5px;
        .enterprise{
          span{
            font-size: 16px;
            margin-left: 11px;
          }
        }
        .num{
          color: #1166CF;
          font-size: 26px;
          font-weight: bold;
        }
      }
      .collectul{
        font-size: 16px;
        width: 100%;
        padding-top: 16px;
        .flexOne{
          flex: 1;
        }
        .text{
          width: 6px;
          height: 6px;
          background: #1166CF;
          border-radius: 50%;
        }
        &:hover{
          cursor: pointer;
          color: #1166CF;
        }
      }
      .more{
        color: #666;
        font-size: 14px;
        &:hover{
          cursor: pointer;
          color: #1166CF;
        }
      }
    }
  }
</style>
