<template>
  <div class="container">
    <el-input placeholder="请输入关键字" @change="searchClick" v-model="keyword" class="input-with-select">
      <template v-if="!searchIndex" slot="prepend">{{searchType === 'menber' ? '会员' : searchType === 'collect' ? '信息' :'项目'}}</template>
      <el-select v-else v-model="select" slot="prepend" @change="searchIndexClick">
        <el-option label="项目" value="1"></el-option>
        <el-option label="会员" value="2"></el-option>
      </el-select>
      <el-button type="primary" slot="append" @click="searchClick">搜索</el-button>
    </el-input>
  </div>
</template>

<script>

export default {
  name: 'Search',
  props: {
    cityId: {
      type: Number,
      default: 0
    },
    keyval: {
      type: String,
      default: ''
    },
    searchType: { // 搜索类型
      type: String,
      default: ''
    },
    searchIndex: { // 判断从首页进入
      type: String,
      default: ''
    }
  },
  watch: {
    keyval(val) {
      this.keyword = val
    },
    cityId(id) {
      this.city_id = id
    }
  },
  data() {
    return {
      keyword: '',
      city_id: 0,
      select: '项目'
    }
  },
  methods: {
    searchIndexClick(e) {
      this.$emit('searchIndex', this.select)
    },
    searchClick() {
      if (this.searchIndex) {
        if (this.select === '项目') {
          localStorage.setItem('approverStatus', JSON.stringify('/projectSearch'))
          this.$router.push({
            path: '/projectSearch',
            name: 'projectSearch',
            params: {
              city_id: this.city_id,
              keyword: this.keyword
            }
          })
        } else {
          localStorage.setItem('approverStatus', JSON.stringify('/memberShow'))
          this.$router.push({
            path: '/memberShow',
            name: 'memberShow',
            params: {
              city_id: this.city_id,
              keyword: this.keyword
            }
          })
        }
        return
      }
      // console.log('11', this.city_id)
      if (this.searchType) {
        this.$emit('searchClick', this.keyword)
        return
      }
      localStorage.setItem('approverStatus', JSON.stringify('/projectSearch'))
      this.$router.push({
        path: '/projectSearch',
        name: 'projectSearch',
        params: {
          city_id: this.city_id,
          keyword: this.keyword
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
  .container{
    /deep/{
      .el-input-group{
        height: 48px;
        line-height: 48px;
      }
      .el-select .el-input {
        min-width: 83px;
      }
      .el-input-group--append .el-input__inner, .el-input-group__prepend{
        height: 100%;
      }
      .input-with-select .el-input-group__prepend {
        background: #FCFDFE;
      }
      .el-input-group__append{
        color: #fff;
        width: 108px;
        text-align: center;
        background: #1166CF;
        &:hover{
          background: #2173D8;
        }
      }
    }
  }
</style>
