<template>
  <div class="classifyList">
    <div class="classifyList__item flex second">
      <div :class="isClass ? 'title' : ''">地区：</div>
      <div :class="isClass ? 'content flex' : 'content flex conMale'">
        <span :class="{'active': !secondClassifyID}" @click="changeSecondClassify(null)">不限</span>
        <template>
          <span
            :class="{'active': secondClassifyID === item.id}"
            v-for="item in classifyList"
            :key="item.id"
            @click="changeSecondClassify(item.id)"
          >{{item.name}}</span>
        </template>
      </div>
      <div v-if="isClass" class="moens cursor" @click="cityMap">
        更多<i class="el-icon-d-arrow-right"></i>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'RegionalMembers',
  props: {
    classifyList: {
      type: Array,
      default: () => []
    },
    cityId: {
      type: Number,
      default: 0
    },
    isClass: { // 判断样式
      type: Boolean,
      default: false
    },
    select: {
      type: String,
      default: ''
    }
  },
  watch: {
    cityId(id) {
      this.secondClassifyID = id
    }
  },
  data() {
    return {
      secondClassifyID: '',
      // classifyList: [],
      firstClassifyIndex: 0
    }
  },
  methods: {
    changeSecondClassify(id) {
      this.secondClassifyID = id
      this.$emit('changeSecondClassify', id)
    },
    cityMap() {
      // this.$router.push('/cityMap')
      this.$router.push({
        path: '/cityMap',
        name: 'cityMap',
        params: {
          city_id: this.secondClassifyID,
          select: this.select
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
  .classifyList{
    background: #fff;
    position: relative;
    .moens{
      position: absolute;
      right: 0;
      font-size: 16px;
      color: #1166cf
    }
      &__item{
        font-size: 16px;
        .title{
          margin-right: 20px;
          height: 26px;
          line-height: 26px;
          font-size: 18px;
          font-family: Source Han Sans CN;
          font-weight: bold;
          color: #1166CF;
        }
        .conMale{
          margin-left: 70px;
        }
        .content{
          flex-wrap: wrap;
          flex: 1;
          span{
            margin-right: 30px;
            margin-bottom: 10px;
            height: 26px;
            line-height: 26px;
            padding: 0 8px;
            border-radius: 4px;
            &:hover:not(.active){
              cursor: pointer;
              color: $button-block;
            }
            &.active{
              background: #E7F2FF;
              color: #1166CF;
            }
          }
        }
        &.first{
          border-bottom: 1px solid #EEE;
          padding-bottom: 20px;
        }
        &.second{
          padding-top: 10px;
        }
      }
    }
</style>
